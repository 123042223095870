<template>
  <div id="hero" class="background-section background-medium h-section pt-navbar bg-blue hidden md:flex flex-col justify-center items-center text-off-white"></div>
  <div id="hero" class="background-section background-small h-section pt-navbar bg-blue flex md:hidden flex-col justify-center items-center text-off-white"></div>
  <div class="bg-off-white py-[100px] md:py-navbar flex flex-col items-center justify-center md:border-y-4 border-dark-blue w-full">
    <div class="w-2/3 flex flex-col gap-16 my-10 text-balance lg:text-left">
      <div class="flex flex-col items-center lg:items-start">
        <h1 class="uppercase w-fit m-0">Mission</h1>
        <p>
          An inclusive and equitable space that’s devoted to helping individuals reach their highest level of emotional, mental and spiritual growth by providing the most suitable spiritual tools and technologies available.
        </p>
      </div>
      <div class="flex flex-col items-center lg:items-start">
        <h1 class="uppercase w-fit m-0">Vision</h1>
        <p>
          A world that’s safe and inclusive for anyone and everyone to be supported in exploring their fullest potential.
        </p>
      </div>
      <div class="flex flex-col items-center lg:items-start">
        <h1 class="uppercase w-fit my-5">What We Do</h1>
        <p>Provide guided meditation, music therapy programs and meditation programs that include Yinnergy – brainwave entrainment technology.</p>
      </div>
    </div>
    <hr class="w-1/3 h-1 my-20 bg-dark-blue"/>
    <div class="w-2/3 flex flex-col gap-40 my-10">
      <div class="flex flex-col lg:flex-row items-center justify-center gap-10 text-left">
        <div class="flex flex-col">
          <h1 class="uppercase w-fit m-0">Meditation</h1>
          <h2 class="text-blue w-fit">with Yinnergy</h2>
          <p >
            An inclusive and equitable space that’s devoted to helping individuals reach their highest level of emotional, mental and spiritual growth by providing the most suitable spiritual tools and technologies available.
          </p>
          <router-link to="/yinnergy" class="uppercase bg-mid-blue text-off-white hover:bg-dark-blue text-3xl w-full lg:w-fit text-center py-2 px-6 my-5">learn more</router-link>
        </div>
        <img src="../assets/images/healing.png" alt="logo" class="home-img">
      </div>
      <div class="flex flex-col lg:flex-row  items-center justify-center gap-10 ">
        <img src="../assets/images/fist.png" alt="logo" class="home-img">
        <div class="flex flex-col items-end text-left lg:text-right">
          <h1 class="uppercase w-fit m-0">Healing workshops</h1>
          <h2 class="text-blue w-full">with True2Soul</h2>
          <p>
            Our priority is holding inclusive and discreet workshops for professional women to find transformative healing.
          </p>
          <a target="_blank" href="https://true2soul.com/what-where/" class=" w-full lg:w-fit text-center"><button class="uppercase bg-mid-blue text-off-white hover:bg-dark-blue w-full text-3xl py-2 px-6 my-5">learn more</button></a>
        </div>
      </div>
    </div>
    <hr class="w-1/3 h-1 my-20 bg-dark-blue"/>
    <ContactSection/>
  </div>
</template>

<script>
import ContactSection from '@/sections/ContactSection.vue';


export default {
  name: 'HomeView',
  components: {
    ContactSection,
  },
};
</script>


<style>
.home-img{
  @apply w-full lg:w-1/3 h-auto rounded-full border-8 border-mid-blue
}
.background-section {
  background-size: contain; /* Cover the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-size: 60%;
}

.background-small{
  background-image: url('@/assets/images/logo/v-logo-white.png');
}

.background-medium{
  background-image: url('@/assets/images/logo/h-logo-white.png');
}

p {
  @apply text-3xl xl:text-4xl text-dark-blue;
}
</style>