<template>
  <div id="contact" class="flex flex-col items-center w-full my-10">
    <div class="w-full md:w-2/3">
      <div>
        <h2 class="p-10">
          For Inquiries please contact
          <a href="mailto:admin@kemorcentre.org"
          ><strong class="text-blue hover:text-dark-blue">admin@kemorcentre.org</strong></a>
        </h2>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
export default {
  name: "ContactSection",
};
</script>
