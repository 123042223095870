import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomeView,
        },
        {
            path: '/about',
            name: 'About',
            component: () => import('@/views/AboutView.vue'),
        },
        {
            path: '/yinnergy',
            name: 'Yinnergy',
            component: () => import('@/views/YinnergyView.vue'),
        },
        {
            path: '/contact',
            name: 'Contact',
            component: () => import('@/views/ContactView.vue'),
        },
        {
            path: '/psycle',
            name: 'Psycle',
            component: () => import('@/views/PsycleView.vue'),
        },
        {
            path: '/elevate',
            name: 'Elevate',
            component: () => import('@/views/ElevateView.vue'),
        },
        // {
        //     path: '/',
        //     name: 'ComingSoon',
        //     component: () => import('@/sections/ComingSoon.vue'),
        // },
        {
            path: '/error',
            name: 'Error',
            component: () => import('@/views/ErrorView.vue'),
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/error', // Redirect to the error page
        },
    ],
    scrollBehavior() {
        // Scroll to the top of the page for each route change
        return { left: 0, top: 0 };
    },
});

export default router;
