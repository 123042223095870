<template>
  <div id="contact" class="bg-black border-t-4 border-bronze-center flex flex-col items-center py-20 wall-bg">
    <p class="w-full md:w-2/3 bg-dark-blue bg-opacity-50 border-y-8 text-3xl py-6 px-6 text-balance text-off-white">
      An inclusive and equitable space that’s devoted to helping individuals reach their highest level of emotional, mental and spiritual growth by providing the most suitable spiritual tools and technologies available.
    </p>
    <p class="text-5xl text-blue mt-20 ">Follow us</p>
    <div class="grid grid-cols-1 items-center justify-center p-4 lg:my-10">
      <a class="items-center justify-center"
         v-for="(image,index) in socials"
         :key="index"
         :href="image.link"
         target="_blank">
        <img class="w-16" :src="image.image" :alt="image.name">
      </a>
    </div>
  </div>
</template>

<script>
import instagram from '@/assets/images/icons/instagram.svg';

export default {
  data() {
    return {
      socials: [
        { image: instagram, link: 'https://www.instagram.com/kemorcentre/' },
      ],
    };
  },
};
</script>